import * as React from "react"
import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import CourseMain from "../../components/course"
import {graphql} from 'gatsby';
import { useTranslation } from "react-i18next";
const CoursePage = ({location}) => {
  
  const { t } = useTranslation();
  return(
    <Layout>
      <Seo
        title="Apprende Online | Cursos sobre desarrollo web"
        description="Aprende NodeJS, React, Angular y todo sobre desarrollo web de la mano de profesionales en el tema."
      />
      <CourseMain location={location}/>
    </Layout>
  )
}

export default CoursePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;