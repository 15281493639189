import { Link, navigate } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import Pagination from '../common/pagination';
import { BASE_URL } from '../../../config';
import axios from 'axios';
import { formatCurrency } from '../../utils';
import StarRating from '../starRating';
import { useTranslation } from 'react-i18next';
const CourseContent = ({location}) => {


    const { t, i18n } = useTranslation();
    const [currentLang, setCurrentLang] = useState(i18n.language);
    const searchParams = new URLSearchParams(location.search)
    const categorySearch = searchParams.get("categories");
    const courseNameSearch = searchParams.get("q");

    const [url, setUrl] = React.useState("/courses")
    const [courses, setCourses] = React.useState(null)
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [categories, setCategories] = useState(null);

    const coursesRef = useRef();

    useEffect(()=>{
          if (coursesRef.current) {
            coursesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
    },[courses])
    
    useEffect(()=>{        
        setCurrentLang(i18n.language)
        
    },[])    

    useEffect(()=>{
        getCategories();
        setCurrentLang(i18n.language)
    },[])

    useEffect(()=>{
        if(location.state?.source == 'menu'){
            handleCategoryClick(categorySearch)
        }
    },[location.state])

    useEffect(()=>{
        getFilteredCourses();
        
    },[selectedCategories,courseNameSearch])



    const getCategories = async () => {
        try {
            const result = await axios.get(BASE_URL+"/subcategories/")
            setCategories(result.data)
        } catch (error) {
            
        }
    }

    const getFilteredCourses = async () => {
        try {
        let url = "/courses";
        if (courseNameSearch) {
            url += url.includes("?") ? "&" : "?";
            url += `name=${courseNameSearch}`;
        }
        if (selectedCategories && selectedCategories.length > 0) {
            url += url.includes("?") ? "&" : "?";
            url += "categories=" + selectedCategories.join("&categories=");
        }
        //navigate(`${currentLang}${url}`);
        const {data} = await axios.get(BASE_URL+url);
        setCourses(data);

        
        } catch (error) {
            setCourses([]);
        }
      }

    const handleCategoryClick = (category) => {
        if (selectedCategories.includes(category)) {
          setSelectedCategories(selectedCategories.filter(item => item !== category));
        } else {
          setSelectedCategories([...selectedCategories, category]);
        }
      };


    return (
        <section className="course-content-area pb-90 mt-4">
            <div className="container">
                <div className="row mb-10">
                    <div className="col-xl-3 col-lg-4 col-md-12">
                    <div className="course-sidebar-widget mb-20">
                        <div className={`course-sidebar-info content-visiable`}>
                            <h3 className="drop-btn">{t('Categories')}</h3>
                            <ul>
                                {categories && categories.map((data,index)=>(
                                    <li key={index}>
                                    <div className="course-sidebar-list">
                                        <input className="" onClick={() => handleCategoryClick(data.id)} type="checkbox" id={`category-${data.id}`} checked={selectedCategories.includes(data.id)} />
                                        <label className="edu-check-label" htmlFor={`category-${data.id}`} >{data.name} </label>
                                    </div>
                                </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    </div>
                    <div className="col-xl-9 col-lg-8 col-md-12 p-4">
                        <div className="row" ref={coursesRef}>
                            {courses && courses.map((data, index)=>(
                                <div className="col-lg-6"  key={index}>
                                <div className="course-wrapper-2 mb-30">
                                    <div className="student-course-img">
                                        <Link to={"/course-details/"+data.slug}><img src={data.introImage} alt="course-img" /></Link>
                                    </div>
                                    <div className="course-cart">
                                        <div className="course-info-wrapper">
                                            <div className="cart-info-body">
                                                <Link to="/courses" className="category-color category-color-1">{data.subcategory?.name}</Link>
                                                <Link to={"/course-details/"+data.slug}><h3>{data.name}</h3></Link>
                                                <div className="cart-lavel">
                                                    <h5>{t('Level')} : <span>{data.level}</span></h5>
                                                    <p>{data.shortDescription}</p>
                                                </div>
                                                <div className="info-cart-text">
                                                    <ul>
                                                        {data.willLearn.map((data,index)=>(
                                                            <li key={index}><i className="far fa-check"></i>{data}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className="course-action">
                                                    <Link to={"/course-details/"+data.slug} className="view-details-btn">{t('ViewDetails')}</Link>
                                                    {/* <button className="wishlist-btn"><i className="flaticon-like"></i></button>
                                                    <Link to={"/course-details/"+data.slug} className="c-share-btn"><i className="flaticon-previous"></i></Link> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="student-course-footer">
                                        <div className="student-course-linkter">
                                            <div className="course-lessons">
                                                <i className="fal fa-tv"></i>
                                                <span className="ms-2">{data.lessons.length} {t('Secciones')}</span>
                                            </div>
                                            <div className="portfolio-price">
                                            <>
                                            {data.isFree && <span>{t('FREE')}</span>}
                                            {!data.isFree &&
                                            <>
                                                {data.priceOld > 0 && <del>USD ${formatCurrency(data.priceOld)}</del>}
                                                <span>USD ${formatCurrency(data.priceNow)}</span>
                                            </>
                                            }
                                               
                                            </>
                                            </div>
                                        </div>
                                        <div className="student-course-text">
                                            <h3><Link to={"/course-details/"+data.slug}>{data.name}</Link></h3>
                                        </div>
                                        <div className="portfolio-user">
                                            <div className="user-icon">
                                                <Link to="/instructor-profile"><i className="fas fa-user"></i>{data.teacher.firstName} {data.teacher.lastName}</Link>
                                            </div>
                                            <StarRating rating={data.rating} count={data.countReviews} />
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                            ))}
                            
                        </div>
                        {/* <div className="row" >
                            <div className='col-12'>
                                <Pagination />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CourseContent;