import React from 'react'
function StarRating({ rating, count }) {
    // Crear un array de estrellas basado en el valor de "rating"
    const stars = Array.from({ length: rating }, (_, index) => (
        <i key={index} className="fas fa-star"></i>
    ));

    return (
        <>
        {rating > 0 &&
            <div className="course-start-icon">
            {count && <>{stars} ({count} Valoraciones)</>}
            {!count && <>{stars}</>}
        </div>
        }
        </>
        
    );
}

export default StarRating;