import React from 'react';
import Breadcrumb from '../common/breadcrumb';
import Footer from '../footer/footer';
import HeaderFour from '../header/headerFour';
import CourseBar from './courseBar';
import CourseContent from './courseContent';
import { useTranslation } from 'react-i18next';

const Main = ({location}) => {

    const {t} = useTranslation();
    
    return (
        <>
            <HeaderFour />
            <main>
                <Breadcrumb breadcrumbTitle={t('Courses')} breadcrumbSubTitle={t('Courses')} />
                {/* <CourseBar /> */}
                <CourseContent location={location} />
            </main>
            <Footer />
        </>
    );
};

export default Main;